import auth from '@/middleware/auth.js'

export default [{
    path: '',
    component: () =>
        import ('@/layouts/Constelacion.vue'),
    children: [
        {
            path: '/garantias_app',
            name: 'garantias_app',
            component: () =>
                import ('@/apps/garantias_app/Dashboard'),
            meta: {
                title: 'Dashboard Garantías',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/fideicomisos',
            name: 'garantias_app_fideicomisos_index',
            component: () =>
                import ('@/apps/garantias_app/pages/Fideicomisos.vue'),
            meta: {
                title: 'Fideicomisos',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/clientes',
            name: 'garantias_app_clientes_index',
            component: () =>
                import ('@/apps/garantias_app/pages/Clientes.vue'),
            meta: {
                title: 'Clientes',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/catalogos',
            name: 'garantias_app_catalogos_index',
            component: () =>
                import ('@/apps/garantias_app/pages/Catalogos.vue'),
            meta: {
                title: 'Catálogos',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/fondeadores',
            name: 'garantias_app_fondeadores_index',
            component: () =>
                import ('@/apps/garantias_app/pages/Fondeadores.vue'),
            meta: {
                title: 'Fondeadores',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/campos_dinamicos',
            name: 'garantias_app_campos_dinamicos_index',
            component: () =>
                import ('@/apps/garantias_app/pages/CamposDinamicos.vue'),
            meta: {
                title: 'Campos Dinámicos',
                middleware: auth
            }
        }
        ,{
            path: '/garantias_app/brokers',
            name: 'garantias_app_brokers_index',
            component: () =>
                import ('@/apps/garantias_app/pages/Brokers.vue'),
            meta: {
                title: 'Brokers',
                middleware: auth
            }
        }
    ]
}]
