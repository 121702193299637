import Vue from 'vue'
import store from '@/store'

export default function auth({to, next, router}) {
	if (!store.state || !store.state.auth || !store.state.auth.token)
		if (router.currentRoute.name != 'login')
			return router.push({name: 'login'});
		else {
			Vue.prototype.$notify({
				group: 'alerts'
				,type:'error'
				,title: 'Error en la sesión'
				,text: 'Necesitas iniciar sesión para poder acceder al sistema'
				,duration: 5000
			})
			return false;
		}

	return next();
}